import { Controller } from '@stimulus/core';
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { Unsubscribable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, throttleTime } from 'rxjs/operators';
import { DeviceHelper } from '../helpers/device-helper';
import { ResponsiveHelper } from '../helpers/responsive-helper';

const HIDE_HEADER_MIN_SCROLL_DOWN = 82;
const SCROLL_THRESHOLD = 32;

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class HeaderController extends Controller {
  public static targets = ['subNavbar', 'accDropdown', 'accDropdownToggle'];

  public element: HTMLElement;

  private readonly subNavbarTarget?: HTMLElement;
  private readonly accDropdownTarget?: HTMLElement;
  private readonly accDropdownToggleTarget?: HTMLElement;
  private readonly hasSubNavbarTarget: boolean;

  private accTimeout: any;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.scheduleScrollListener();

    this.subscriber = fromEvent(window, 'load').subscribe(() => {
      this.subscriber = fromEvent(window, 'resize').pipe(
        startWith(null),
        debounceTime(40),
      ).subscribe(() => this.updateNavWidthSSVar());
    });
  }

  /**
   * Handle the delayed visibility of the account dropdown to check if the user went to the dropdown
   * @param event - The event that triggered the visibility change.
   */
  public handleAccDropdownVisibilityDelayed(event) {
    if (this.accTimeout) {
      clearTimeout(this.accTimeout);
    }
    this.accTimeout = setTimeout(() => {
      this.handleAccDropdownVisibility(event);
    }, 200);
  }

  /**
   * Handle the visibility of the account dropdown.
   * @param event - The event that triggered the visibility change.
   */
  public handleAccDropdownVisibility(event) {
    // prevent the default action and bubbling of the event

    console.log('handleAccDropdownVisibility');
    clearTimeout(this.accTimeout);

    const isTouchScreen = DeviceHelper.isTouchScreen();
    const isHoverEvent = event.type === 'mouseenter';
    const isClickEvent = event.type === 'click';
    const isMouseLeaveEvent = event.type === 'mouseleave';

    const eventTargetIsAccDropdownElement = event.target === this.accDropdownTarget
      || this.accDropdownTarget.contains(event.target);
    const eventTargetIsAccDropdownToggleElement = event.target === this.accDropdownToggleTarget
      || this.accDropdownToggleTarget.contains(event.target);

    if (eventTargetIsAccDropdownToggleElement || eventTargetIsAccDropdownElement) {
      event.preventDefault();
      event.stopPropagation();
    }

    // check if the accDropdownToggleTarget has a data-acc-hover-time attribute to check if the user just hovered over the account dropdown
    // so it prevents the dropdown from closing when the user clicks on the dropdown
    const accHoverTime = this.accDropdownToggleTarget.dataset.accHoverTime ? parseInt(this.accDropdownToggleTarget.dataset.accHoverTime, 10) : 0;
    const currentTime = new Date().getTime();
    const clickedTooSoon = eventTargetIsAccDropdownToggleElement && isClickEvent && (currentTime - accHoverTime < 200);

    if ((isClickEvent) || (isHoverEvent && !isTouchScreen)) {
      if (this.hasAccDropdownTarget) {
        if (this.accDropdownTarget.classList.contains('show') && (!eventTargetIsAccDropdownElement && isClickEvent) && !clickedTooSoon) {
          this.closeAccDropdown();
        } else {
          this.showAccDropdown(isHoverEvent);
        }
      }
    } else if (isMouseLeaveEvent && eventTargetIsAccDropdownElement && !isTouchScreen) {
      this.closeAccDropdown();
    }
  }

  public toggleSubNavbar(event) {
    if (this.hasSubNavbarTarget) {
      if (!this.subNavbarTarget.classList.contains('animate')) {
        this.subNavbarTarget.classList.add('animate');
      }
      this.subNavbarTarget.classList.toggle('show');
      const isExpanded = this.subNavbarTarget.classList.contains('show');
      event.currentTarget.setAttribute('aria-expanded', isExpanded.toString());
    }
  }

  public goToAllOffers() {
    const allOffersButton = this.accDropdownTarget.querySelector('.all-offers');
    if (allOffersButton && allOffersButton.href) {
      window.location.href = allOffersButton.href;
    }
  }

  private showAccDropdown(isHoverEvent: boolean) {
    if (!this.accDropdownTarget.classList.contains('animate')) {
      this.accDropdownTarget.classList.add('animate');
    }
    this.accDropdownTarget.classList.add('show');
    this.accDropdownToggleTarget.setAttribute('aria-expanded', 'true');
    document.body.classList.add('places-dropdown-open');
    this.accDropdownToggleTarget.dataset.accHoverTime = isHoverEvent ? new Date().getTime().toString() : '0';
  }

  private closeAccDropdown() {
    this.accDropdownTarget.classList.remove('show');
    this.accDropdownToggleTarget.setAttribute('aria-expanded', 'false');
    document.body.classList.remove('places-dropdown-open');
    this.hideSubNavbar();
  }

  private scheduleScrollListener() {
    this.subscriber = fromEvent(window, 'scroll').pipe(
      throttleTime(100),
      map(() => window.scrollY),
      distinctUntilChanged(),
      pairwise(),
      filter(([prevScrollY, currentScrollY]) => Math.abs(currentScrollY - prevScrollY) >= SCROLL_THRESHOLD),
      map(([prevScrollY, currentScrollY]) => ({
        scrollY: currentScrollY,
        direction: currentScrollY > prevScrollY ? 'down' : 'up',
      }))
    ).subscribe(({ scrollY, direction }) => {
      this.onScroll(scrollY, direction);
      this.closeAccDropdown(false);
    });
  }

  private onScroll(scrollY: number, direction: 'up' | 'down') {
    if (this.shouldHideHeaderOnScroll) {
      if (scrollY >= 0) {
        if (this.isNavBarVisible && direction === 'down' && this.scrolledPastTopOfPage(scrollY)) {
          this.hideNavBar();
        } else if (!this.isNavBarVisible && direction === 'up') {
          this.showNavBar();
        }
      }
    } else {
      this.showNavBar();
    }
  }

  private scrolledPastTopOfPage(scrollY: number): boolean {
    return scrollY > HIDE_HEADER_MIN_SCROLL_DOWN;
  }

  private showNavBar() {
    document.body.classList.remove('nav-hide');
  }

  private hideNavBar() {
    document.body.classList.add('nav-hide');
    this.hideSubNavbar();
  }

  private get isNavBarVisible(): boolean {
    return !document.body.classList.contains('nav-hide');
  }

  private get shouldHideHeaderOnScroll(): boolean {
    return ResponsiveHelper.isBreakpointBelow('md');
  }

  private hideSubNavbar() {
    if (this.hasSubNavbarTarget) {
      this.subNavbarTarget.classList.remove('show');
      const toggleElement = this.element.querySelector('[data-action*="toggleSubNavbar"]');
      if (toggleElement) {
        toggleElement.setAttribute('aria-expanded', 'false');
      }
    }
  }

  private updateNavWidthSSVar() {
    const navLogoElement = document.querySelector('.nav-logo');
    const navMainElement = document.querySelector('.nav-main .main-nav .navbar-nav');
    const navSubElement = document.querySelector('.nav-sub');

    if (navLogoElement && navMainElement && navSubElement) {
      const navLogoWidth = navLogoElement.getBoundingClientRect().width;
      let navMainWidth = navMainElement.getBoundingClientRect().width;
      let navSubWidth = navSubElement.getBoundingClientRect().width;

      if (ResponsiveHelper.isBreakpointAtLeast('xxl')) {
        if ((navLogoWidth + navMainWidth) > navSubWidth) {
          navSubWidth = navLogoWidth + navMainWidth;
        } else {
          navMainWidth = navSubWidth - navLogoWidth;
        }
      }

      document.documentElement.style.setProperty('--nav-main-width', `${navMainWidth}px`);
      document.documentElement.style.setProperty('--nav-sub-width', `${navSubWidth}px`);
    }

  }


}
