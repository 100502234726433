import { Controller } from '@stimulus/core';
import { ResponsiveHelper } from '../helpers/responsive-helper';
import BodyController from './body_controller';

export default class ConversationsController extends Controller {
  public static targets = ['details', 'conversation', 'headerConversations', 'messages', 'translate', 'textarea', 'noDirectContactAlert', 'noDirectContactAlertText'];

  private readonly detailsTarget: HTMLElement;
  private readonly conversationTarget: HTMLElement;
  private readonly headerConversationsTarget: HTMLElement;
  private readonly messagesTarget: HTMLElement;
  private readonly translateTarget: HTMLElement;
  private readonly textareaTarget: HTMLTextAreaElement;
  private readonly noDirectContactAlertTarget: HTMLTextAreaElement;
  private readonly noDirectContactAlertTextTarget: HTMLTextAreaElement;
  private readonly hasNoDirectContactAlertTarget: boolean;

  public connect() {
    this.scrollToBottom();
    this.initStickyInput();
    this.textareaTarget.addEventListener('input',  this.autoGrow.bind(this));
  }

  public archive(event: Event) {
    alert("archive");
  }

  public mobileShowDetails(event: Event) {
    this.detailsTarget.classList.add('show');
  }

  public mobileHideDetails(event: Event) {
    this.detailsTarget.classList.remove('show');
  }

  public mobileShowConversation(event: Event) {
    this.conversationTarget.classList.add('show');
    this.scrollToBottom();
  }

  public mobileHideConversation(event: Event) {
    this.conversationTarget.classList.remove('show');
  }

  public toggleSearch(event: Event) {
    this.headerConversationsTarget.classList.toggle('show-search');
  }

  public removeTranslatedNote(event: Event) {
    if (this.translateTarget) {
      this.translateTarget.remove();
    }
  }

  private initStickyInput() {
    const preventDefault = (event) => {
      let currentElement = event.target;
      let isChild = currentElement === this.noDirectContactAlertTextTarget;
      while (currentElement) {
        if (currentElement === this.messagesTarget) {
          isChild = true;
          break;
        }
        currentElement = currentElement.parentElement;
      }
      if (!isChild) {
        event.preventDefault();
      }
    };
    const onFocused = () => {
      if (BodyController.isIOSPlatform && ResponsiveHelper.isBreakpointBelow('sm')) {
        document.addEventListener("touchmove", preventDefault, { capture: true, passive: false });
        document.addEventListener("touchstart", preventDefault);
        requestAnimationFrame(() => {
          setTimeout(() => {
            window.scrollTo(0, 0);
            this.scrollToBottom();
          }, 200);
        });
      }

      if (this.hasNoDirectContactAlertTarget) {
        this.noDirectContactAlertTarget.classList.remove('d-none');
      }
    };
    const onBlurred = () => {
      if (BodyController.isIOSPlatform && ResponsiveHelper.isBreakpointBelow('sm')) {
        document.removeEventListener("touchmove", preventDefault, { capture: true });
        document.removeEventListener("touchstart", preventDefault);
        this.scrollToBottom();
      }
    };

    this.textareaTarget.addEventListener('focus', onFocused);
    this.textareaTarget.addEventListener('blur', onBlurred);
  }

  public hideTranslations(event: Event) {
    this.conversationTarget.dataset.showTranslations = "false";
  }

  public showTranslations(event: Event) {
    this.conversationTarget.dataset.showTranslations = "true";
  }

  private scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
  }

  private autoGrow() {
    // Check if the cursor is at the end of the textarea
    const isAtEnd = this.textareaTarget.selectionStart === this.textareaTarget.value.length;

    // Reset the height to 'auto' to recalculate the required height
    this.textareaTarget.style.height = 'auto';

    // Calculate the padding height
    const style = getComputedStyle(this.textareaTarget);
    const paddingTop = parseInt(style.paddingTop);
    const paddingBottom = parseInt(style.paddingBottom);
    const borderTop = parseInt(style.borderTopWidth);
    const borderBottom = parseInt(style.borderBottomWidth);

    // Calculate the scrollHeight of the textarea, which includes overflow content
    const contentHeight = this.textareaTarget.scrollHeight;

    // Set the new height of the textarea based on scrollHeight
    let newHeight = contentHeight + borderTop + borderBottom;

    // Limit the maximum height to 4 lines
    const lineHeight = parseInt(style.lineHeight);
    const maxHeight = (lineHeight * 4) + paddingTop + paddingBottom + borderTop + borderBottom;
    newHeight = Math.min(newHeight, maxHeight);

    // Set the new height of the textarea
    this.textareaTarget.style.height = `${newHeight}px`;

    // Scroll to the bottom if the new height exceeds the maximum allowed and the cursor is at the end
    if (newHeight === maxHeight && isAtEnd) {
      this.textareaTarget.scrollTop = this.textareaTarget.scrollHeight;
    }
  }


}
