import { Controller } from "@stimulus/core";
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { BehaviorSubject, Unsubscribable } from "rxjs";
import { debounceTime, filter, distinctUntilChanged, tap } from "rxjs/operators";
import { secureFetch } from "../auth/secure_fetch_function";
import { EventHelper } from "../helpers/event-helper";
import { DateHelper } from "../helpers/date-helper";
import { ViewportHelper } from '../helpers/viewport-helper';
import { LogHelper } from '../helpers/log-helper';
import { HistoryHelper } from '../helpers/history-helper';
import { ResponsiveHelper } from "../helpers/responsive-helper";

interface BookingDetail {
  startDate?: Date;
  endDate?: Date;
  groupSize?: number;
  groupSizeConfirmed: boolean;
  itemId?: number;
}
interface PriceInfo {
  available: boolean;
  summary: string;
  price_per_night_formatted: string;
  price_total_formatted: string;
  price_per_night_whole: string;
  price_per_night_cents: string;
  price_per_night?: string;
  listing_id: string;
  redirect_to: string;
}

const UNSELECTED_ITEM_ID = -1;

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class BookingConsoleController extends Controller {
  public static targets = [
    'form', 'guestSelect', 'totalPrice', 'nightsAndPricePerNight',
    'pricePerNight', 'pricePerNightWhole', 'pricePerNightCents', 'priceOverview',
    'buySection', 'buyButton', 'startDateISO8601', 'endDateISO8601', 'datePicker',
    'listingId', 'itemId', 'guestLabel', 'selectedItemImage', 'selectedItemTitle',
    'conversationForm',
    'conversationFormItemId', 'conversationFormStartDate', 'conversationFormEndDate', 'conversationFormGroupSize',
    'selectedPeriodWarning',
  ];

  private bookingDetailSubject: BehaviorSubject<BookingDetail>;

  private readonly formTarget!: HTMLFormElement;
  private readonly guestSelectTarget!: HTMLSelectElement;
  private readonly guestLabelTarget?: HTMLLabelElement;
  private readonly hasGuestLabelTarget?: boolean;
  private readonly totalPriceTargets!: HTMLElement[];
  private readonly nightsAndPricePerNightTarget!: HTMLElement;
  private readonly pricePerNightTargets!: HTMLElement[];
  private readonly pricePerNightWholeTargets!: HTMLElement[];
  private readonly pricePerNightCentsTargets!: HTMLElement[];
  private readonly datePickerTarget!: HTMLElement;
  private readonly buyButtonTargets!: HTMLButtonElement[];
  private readonly startDateISO8601Target: HTMLInputElement;
  private readonly endDateISO8601Target: HTMLInputElement;
  private readonly listingIdTarget: HTMLInputElement;
  private readonly itemIdTarget?: HTMLInputElement;
  private readonly hasItemIdTarget: boolean;
  private readonly selectedItemImageTarget!: HTMLImageElement;
  private readonly selectedItemTitleTarget!: HTMLSpanElement;
  private readonly selectedPeriodWarningTarget?: HTMLElement;
  private readonly hasSelectedPeriodWarningTarget: boolean;
  private readonly conversationFormTarget?: HTMLFormElement;
  private readonly conversationFormItemIdTarget?: HTMLInputElement;
  private readonly hasConversationFormItemIdTarget: boolean;
  private readonly conversationFormStartDateTarget?: HTMLInputElement;
  private readonly conversationFormEndDateTarget?: HTMLInputElement;
  private readonly conversationFormGroupSizeTarget?: HTMLInputElement;

  private priceUrl: string;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  private requireItemConfirmationOnMobile: boolean = false;
  private confirmedItemId: number = null;
  private interactionStarted: boolean = false;

  public initialize() {
    this.priceUrl = this.data.get('priceUrl');
  }

  public connect() {
    this.initBookingDetailSubject();
    this.addBookingDetailsUpdatePriceSubscriber();
    this.addBookingDetailsSetModeSubscriber();
    this.addBookingDetailsInputUpdatedSubscriber();
    this.setInitialStateAndMode();

    // FIXME: Find better solution
    // Use timeout to wait until listing-items and date-picker are loaded
    setTimeout(() => this.dispatchInputUpdated(this.currentBookingDetail), 100);
  }

  public openDatePicker() {
    this.interactionStarted = true;
    this.toggleConsole();
    this.dispatchDatePickerOpen();
  }

  public openGroupSizePicker() {
    this.mode = 'select-group-size';
    this.currentBookingDetail.groupSizeConfirmed = false;
    this.toggleConsole();
  }

  public onDatesSelected(event: CustomEvent) {
    event.stopImmediatePropagation();
    EventHelper.dispatch(this.element, 'booking-console-snd-apply-dates',
      { startDate: event.detail.startDate, endDate: event.detail.endDate });

    this.interactionStarted = true;
    const { startDate, endDate } = event.detail;
    const groupSize = parseInt(this.guestSelectTarget.value, 0);

    this.bookingDetailSubject.next({
      ...this.bookingDetailSubject.getValue(),
      startDate,
      endDate,
      groupSize,
      groupSizeConfirmed: true
    })
  }

  public onGuestsChanged() {
    this.interactionStarted = true;
    const groupSize = parseInt(this.guestSelectTarget.value, 0);
    this.guestLabelInnerText = groupSize.toString();
    this.bookingDetailSubject.next({
      ...this.bookingDetailSubject.getValue(),
      groupSize,
      groupSizeConfirmed: true
    })
    EventHelper.dispatch(window, 'booking-console-snd-on-guests-changed', { groupSize: groupSize });
  }

  public disconnect() {
  }

  public closeIfEmpty() {
    const bookingDetail = this.bookingDetailSubject.getValue();
    if (bookingDetail?.startDate === null && bookingDetail?.endDate === null) {
      this.toggleConsole();
    } else {
      this.mode = 'book';
    }
  }

  public submitBooking() {
    if (this.isBookingDetailComplete(this.currentBookingDetail)) {
      if (this.data.has('previewMessage') && this.data.get('previewMessage').length > 0) {
        alert(this.data.get('previewMessage'))
      } else {
        this.formTarget.submit();
      }
    } else {
      this.element.classList.add('show');
      this.dispatchDatePickerOpen();
    }
  }

  public toggleConsole() {
    this.interactionStarted = true;
    const isShown = this.element.classList.toggle('show');
    if (isShown) {
      this.updateModeForBookingDetailState(this.currentBookingDetail);
      this.element.classList.remove('minimize-animation');
      window.document.body.classList.add('noscroll-sm');
    } else {
      this.element.classList.add('minimize-animation');
      window.document.body.classList.remove('noscroll-sm');
    }
  }

  public flashGroupSizeSelect() {
    this.guestSelectTarget.parentElement.classList.remove('flash-div');
    // CSS Animation hack: force document reflow to fix triggering an animation again
    // tslint:disable-next-line:no-unused-expression
    (this.guestSelectTarget as HTMLElement).offsetWidth;
    this.guestSelectTarget.parentElement.classList.add('flash-div');
    setTimeout(() => this.guestSelectTarget.parentElement.classList.remove('flash-div'), 20000);
  }

  public removeFlashGroupSizeSelect() {
    this.guestSelectTarget.parentElement.classList.remove('flash-div');
  }

  public selectRooms() {
    EventHelper.dispatch(window, 'booking-console-snd-flash-items', {});
  }

  public reselectItem() {
    this.interactionStarted = true;
    this.mode = 'select-item';
  }

  public incrementGroupSize() {
    const currentGroupSize = parseInt(this.guestSelectTarget.value, 0);
    if (currentGroupSize === parseInt(this.data.get('maxGroupSize'), 0)) {
      return;
    }

    const newGroupSize = currentGroupSize + 1;
    this.guestSelectTarget.value = newGroupSize.toString();
    this.guestLabelInnerText = newGroupSize.toString();
  }

  public decrementGroupSize() {
    const currentGroupSize = parseInt(this.guestSelectTarget.value, 0);
    if (currentGroupSize <= 1) {
      return;
    }

    const newGroupSize = currentGroupSize - 1;
    this.guestSelectTarget.value = newGroupSize.toString();
    this.guestLabelInnerText = newGroupSize.toString();
  }

  public onItemSelected(event: CustomEvent) {
    this.itemIdTarget.value = event.detail.id;

    if (event.detail.id) {
      this.priceUrl = event.detail.priceUrl;
      this.selectedItemTitleTarget.innerText = event.detail.title;
      this.selectedItemImageTarget.querySelector('img').src = event.detail.imageSrc;
      if (event.detail.autoSelected) {
        this.requireItemConfirmationOnMobile = (this.confirmedItemId !== event.detail.id);
      } else {
        this.requireItemConfirmationOnMobile = false;
        this.confirmedItemId = event.detail.id;
      }
    }

    const itemId = event.detail.id ? parseInt(event.detail.id, 0) : UNSELECTED_ITEM_ID;
    this.bookingDetailSubject.next({
      ...this.bookingDetailSubject.getValue(),
      itemId
    })

    if (event.detail.submit) {
      this.submitBooking();
    }
  }

  public setPreviousState() {
    if (this.mode === 'select-group-size') {
      return;
    } else if (this.mode === 'choose-dates') {
      this.currentBookingDetail.groupSizeConfirmed = false;
      this.mode = 'select-group-size';
    } else if (this.mode === 'select-item') {
      this.mode = 'choose-dates';
      this.dispatchDatePickerOpen();
    } else if (this.mode === 'book') {
      this.mode = 'select-item';
    }
  }

  public clickNewConversation() {
    this.conversationFormTarget?.submit();
  }

  private dispatchDatePickerReload() {
    EventHelper.dispatch(this.datePickerTarget, 'date-picker-rcv-reload', {});
  }

  private dispatchDatePickerOpen() {
    EventHelper.dispatch(this.datePickerTarget, 'date-picker-rcv-open-picker', {});
  }

  private initBookingDetailSubject() {
    const startDate = this.startDateISO8601Target.value ? DateHelper.parseISODateUTC(this.startDateISO8601Target.value) : null;
    const endDate = this.endDateISO8601Target.value ? DateHelper.parseISODateUTC(this.endDateISO8601Target.value) : null;
    const groupSize = parseInt(this.guestSelectTarget.value, 0);
    const groupSizeConfirmed = !this.requireGroupSizeSelection;
    const itemId = this.requireItemSelection ? this.parseItemIdTargetValue() : undefined;

    this.bookingDetailSubject = new BehaviorSubject({ startDate, endDate, groupSize, itemId, groupSizeConfirmed });
  }

  private setInitialStateAndMode() {
    switch (this.data.get('openInMode')) {
      case 'calendar':
        this.element.classList.add('show');
        // Don't set mode, it will be determined by the bookingDetails mode subscriber
        // The datepicker will be opened independently through the HTML data 'show' attribute
        break;
      case 'items':
        this.element.classList.add('show');
        this.mode = 'select-item';
    }
  }

  private addBookingDetailsUpdatePriceSubscriber() {
    this.subscriber = this.bookingDetailSubject.pipe(
      tap(bookingDetail => console.log("Subject:", bookingDetail)),
      debounceTime(100),
      distinctUntilChanged((former, current) => (
        former.startDate === current.startDate &&
        former.endDate === current.endDate &&
        (former.groupSize === current.groupSize || isNaN(current.groupSize)) &&
        former.itemId === current.itemId
      )),
      filter(bookingDetail => this.isBookingDetailComplete(bookingDetail)),
    ).subscribe(async (bookingDetail) => {
      await this.fetchAndUpdatePriceInfo(bookingDetail);
    })
  }

  private addBookingDetailsSetModeSubscriber() {
    this.subscriber = this.bookingDetailSubject.subscribe(
      async bookingDetail => this.updateModeForBookingDetailState(bookingDetail)
    )
  }

  private addBookingDetailsInputUpdatedSubscriber() {
    this.subscriber = this.bookingDetailSubject.pipe(
      tap(bookingDetail => this.updateConversationForm(bookingDetail)),
      distinctUntilChanged((former, current) => (
        former.startDate === current.startDate &&
        former.endDate === current.endDate &&
        (former.groupSize === current.groupSize || isNaN(current.groupSize))
      )),
    ).subscribe(async bookingDetail => {
      this.dispatchInputUpdated(bookingDetail);
      this.dispatchDatePickerReceiveGroupSizeUpdated(bookingDetail.groupSize);
    })
  }

  private updateModeForBookingDetailState(bookingDetail: BookingDetail) {
    if (!bookingDetail.groupSizeConfirmed) {
      this.mode = 'select-group-size';
    } else if (bookingDetail.startDate === null || bookingDetail.endDate === null) {
      this.mode = 'choose-dates';
      if (this.interactionStarted) {
        this.dispatchDatePickerOpen();
      }
    } else if (bookingDetail.itemId === UNSELECTED_ITEM_ID) {
      this.mode = 'select-item';
    } else if (this.shouldConfirmAutoSelectedItem()) {
      this.mode = 'select-item';
    } else {
      this.mode = 'book';
    }
  }

  private dispatchInputUpdated(bookingDetail: BookingDetail) {
    EventHelper.dispatch(this.element, 'booking-console-snd-input-updated', {
      startDate: bookingDetail.startDate,
      endDate: bookingDetail.endDate,
      groupSize: bookingDetail.groupSize,
      itemId: bookingDetail.itemId
    })
  }

  private dispatchDatePickerReceiveGroupSizeUpdated(groupSize: number) {
    EventHelper.dispatch(this.element, 'date-picker-rcv-group-size-updated', { groupSize });
  }

  private updateConversationForm(bookingDetail: BookingDetail) {
    if (this.hasConversationFormItemIdTarget) {
      if (bookingDetail.itemId && bookingDetail.itemId !== UNSELECTED_ITEM_ID) {
        this.conversationFormItemIdTarget.value = bookingDetail.itemId.toString();
      } else {
        this.conversationFormItemIdTarget.value = null;
      }
      this.conversationFormStartDateTarget.value = bookingDetail.startDate ? DateHelper.isoDateString(bookingDetail.startDate) : '';
      this.conversationFormEndDateTarget.value = bookingDetail.endDate ? DateHelper.isoDateString(bookingDetail.endDate) : '';
      this.conversationFormGroupSizeTarget.value = bookingDetail.groupSize.toString();
    }
  }

  private updatePrice(priceInfo: PriceInfo) {
    this.totalPriceTargets.forEach(el => {
      this.setContent(el, priceInfo.price_total_formatted);
    });

    this.setContent(this.nightsAndPricePerNightTarget, priceInfo.summary);

    this.pricePerNightTargets.forEach(el => {
      this.setContent(el, `${priceInfo.price_per_night_formatted}`);
      EventHelper.dispatch(this.element, 'booking-console-snd-price-per-night-updated', {
        pricePerNightFormatted: priceInfo.price_per_night_formatted,
        pricePerNight: priceInfo.price_per_night
      });
    });

    this.pricePerNightWholeTargets.forEach(el => {
      this.setContent(el, `€ ${priceInfo.price_per_night_whole}`);
    });

    if (parseInt(priceInfo.price_per_night_cents, 10) > 0) {
      this.pricePerNightCentsTargets.forEach(el => {
        this.setContent(el, `,${priceInfo.price_per_night_cents}`);
      });
    }
  }

  private async fetchAndUpdatePriceInfo(bookingDetail) {
    this.mode = 'loading';
    this.disableBuyButtons();
    this.removeDateUnavailableAlert();
    this.fetchPriceInfo(bookingDetail)
      .then(priceInfo => {
        if (priceInfo.available) {
          this.updatePrice(priceInfo);
          if (priceInfo.listing_id != null) {
            this.listingIdTarget.value = priceInfo.listing_id.toString();
          }
          this.updateModeForBookingDetailState(this.currentBookingDetail);
        } else {
          if (priceInfo.redirect_to) {
            this.redirectWhenDatesNotAvailable(bookingDetail, priceInfo.redirect_to);
          } else {
            this.mode = 'choose-dates';
            this.dispatchDatePickerReload();
            alert("De door jou geselecteerde datum is helaas net door iemand anders via Vipio geboekt. Kies snel een andere beschikbare datum voor jouw boeking");
          }
        }
      })
      .catch(response => {
        if (response.status === 400) {
          response.json().then(json => {
            if (json.errors?.group_size === 'too_large') {
              this.handleGroupSizeTooLarge();
            }
          })
        } else {
          this.restartFlowOnError();
        }
      }).catch(error => {
        LogHelper.logError(error);
        this.restartFlowOnError();
      });
    this.enableBuyButtons();
    this.scrollToTopBookingConsole();
  }

  private scrollToTopBookingConsole() {
    ViewportHelper.scrollIntoViewIfNeeded(this.element, 225);
  }

  private restartFlowOnError() {
    this.mode = 'choose-dates';
    this.dispatchDatePickerReload();
    alert("Er is onverwacht iets misgegaan. Probeer het nog eens, of probeer een andere datum voor jouw boeking");
  }

  private handleGroupSizeTooLarge() {
    this.mode = 'select-item';
  }

  private fetchPriceInfo(bookingDetail: BookingDetail): Promise<PriceInfo> {
    const params = {
      start_date: DateHelper.isoDateString(bookingDetail.startDate),
      end_date: DateHelper.isoDateString(bookingDetail.endDate),
      group_size: bookingDetail.groupSize,
      item_id: (this.hasItemIdTarget && this.itemIdTarget.value)
    };
    const url = `${this.priceUrl}?${Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&')}`;
    return secureFetch(url).then(response => (
      response.ok ? response.text() : Promise.reject(response)
    )).then(textBody => {
      try {
        return JSON.parse(textBody);
      } catch (e) {
        return Promise.reject({ body: textBody, type: 'unparsable' });
      }
    })
  }

  private redirectWhenDatesNotAvailable(bookingDetail: BookingDetail, redirectTo: string) {
    this.startDateISO8601Target.value = null;
    this.endDateISO8601Target.value = null;
    HistoryHelper.visit(redirectTo);
  }

  private setContent(element: Element, content: string) {
    element.classList.toggle('loading', false);
    element.querySelector('[data-content]').innerHTML = content;
  }

  private disableBuyButtons() {
    this.buyButtonTargets.forEach(btn => {
      btn.setAttribute('disabled', 'disabled');
      btn.classList.add('disabled');
    });
  }

  private enableBuyButtons() {
    this.buyButtonTargets.forEach(btn => {
      btn.removeAttribute('disabled');
      btn.classList.remove('disabled');
    });
  }

  private isBookingDetailComplete(bookingDetail: BookingDetail): boolean {
    return bookingDetail.groupSize &&
      bookingDetail.groupSizeConfirmed &&
      bookingDetail.startDate && bookingDetail.endDate &&
      (bookingDetail.itemId !== UNSELECTED_ITEM_ID);
  }

  get currentBookingDetail() {
    return this.bookingDetailSubject.getValue();
  }

  private set mode(mode: 'select-group-size' | 'choose-dates' | 'select-item' | 'loading' | 'book') {
    console.log('Set booking console mode: ' + mode);
    this.data.set('mode', mode);
  }

  private get mode() {
    // @ts-ignore
    return this.data.get('mode');
  }

  private get requireItemSelection(): boolean {
    return this.hasItemIdTarget;
  }

  private get requireGroupSizeSelection(): boolean {
    return this.requireItemSelection && this.data.get('groupSizeRequired') !== 'true' && ResponsiveHelper.isMobile;
  }

  private set guestLabelInnerText(text: string) {
    if (this.hasGuestLabelTarget) {
      this.guestLabelTarget.innerText = text;
    }
  }

  private parseItemIdTargetValue() {
    return this.itemIdTarget.value ? parseInt(this.itemIdTarget.value, 0) : UNSELECTED_ITEM_ID;
  }

  private shouldConfirmAutoSelectedItem() {
    return this.requireItemConfirmationOnMobile === true && ResponsiveHelper.screenState === 'mobile';
  }

  private removeDateUnavailableAlert() {
    if (this.hasSelectedPeriodWarningTarget) {
      this.selectedPeriodWarningTarget.classList.add('d-none');
      EventHelper.dispatch(window, 'booking-console-snd-close-selected-period-warning');
    }
  }
}
