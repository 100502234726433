
export class DeviceHelper {
  static isIOSPlatform() {
    return (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform))
      || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform));
  }

  static isTouchScreen() {
    if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
      return true;
    }

    if ('msMaxTouchPoints' in navigator && navigator.msMaxTouchPoints > 0) {
      return true;
    }

    const mQ = window.matchMedia && matchMedia("(pointer: coarse)");
    if (mQ && mQ.media === "(pointer: coarse)") {
      return !!mQ.matches;
    }

    if ('ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
      return true;
    }

    return false;
  }

}
