import { Controller } from '@stimulus/core';
import { CombineSubscriptions, DestroySubscribers } from 'ngx-destroy-subscribers';
import { Unsubscribable, fromEvent } from 'rxjs';
import { Autoplay, EffectCreative, Grid, Keyboard, Navigation, Pagination, Swiper, SwiperOptions, Thumbs, Zoom } from 'swiper';
import { EventHelper } from "../helpers/event-helper";
import { ResponsiveHelper } from "../helpers/responsive-helper";

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class CarouselController extends Controller {
  public static targets = ['gallery', 'controls', 'thumbs'];

  private readonly galleryTarget!: HTMLElement;
  private readonly hasGalleryTarget!: boolean;
  private readonly thumbsTarget!: HTMLElement;
  private readonly hasThumbsTarget!: boolean;
  private readonly controlsTarget!: HTMLElement;
  private readonly hasControlsTarget!: boolean;

  private slider: Swiper;
  private thumbs: Swiper;

  @CombineSubscriptions()
  public subscriber: Unsubscribable;

  public connect() {
    if (!this.data.has('disableAutoInit')) {
      this.initGallery();
    }
    
    // Addressing Safari's behavior with Turbolinks where it doesn't scroll to the top on page change
    // This ensures the window scrolls to the top
    // Safari has issues when two elements are scrolling simultaneously, which causes it to stop scrolling the window
    // Likely, Swiper interacts with the scroll event on an element when slidesPerView is set to a high number
    // Tested with Swiper v7 - v11
    if (!document.documentElement.hasAttribute('data-turbolinks-scroll')) {
      document.documentElement.setAttribute('data-turbolinks-scroll', 'true');
      this.subscriber = fromEvent(document, 'turbolinks:render').subscribe(() => {
        setTimeout(() => {
          window.scrollTo(0, 0); 
        }, 250);
      });      
    }
  }

  public disconnect() {
    this.data.delete('galleryInitialized');
    this.galleryTarget.classList.remove('swiper-initialized');    
  }

  public setIndex(event: CustomEvent) {
    const { index } = event.detail;
    this.slider.slideTo(index + 1);
  }

  public slideLeft(e) {
    this.slider.slidePrev();
    e.stopPropagation();
    e.preventDefault();
  }

  public slideRight(e) {
    this.slider.slideNext();
    e.stopPropagation();
    e.preventDefault();
  }

  public openFullscreen() {
    if (this.fullscreenAllowed()) {
      this.galleryTarget.classList.add('fullscreen');
      this.element.classList.add('fullscreen');
      this.element.classList.remove('thumbs-lg');
      EventHelper.dispatch(this.element, 'fullscreen', {add: ['fullscreen-carousel'], remove: ['room']});
    }
  }

  public closeFullscreen() {
      this.galleryTarget.classList.remove('fullscreen');
      this.element.classList.remove('fullscreen');
      this.element.classList.add('thumbs-lg');
      EventHelper.dispatch(this.element, 'fullscreen', {add: ['room'], remove: ['fullscreen-carousel']});
  }

  public initGallery() {
    if (!this.data.get('galleryInitialized')) {
      this.data.set('galleryInitialized', 'true');
      if (!this.element.id) {
        this.galleryTarget.id = 'gallery_' + Math.round(Math.random() * 80000);
      }
      if (!this.galleryTarget.classList.contains('swiper-initialized')) {
        const nrOfItems = this.data.has('nrOfItems') ? parseFloat(this.data.get('nrOfItems')) : 1;
        const nrOfRows = this.data.has('nrOfRows') ? parseFloat(this.data.get('nrOfRows')) : 1;
        let swiperOptions: SwiperOptions = {
          modules: [Keyboard, Navigation, Pagination, Thumbs, Zoom, Grid],
          slidesPerView: nrOfItems,
          grid: {
            rows: nrOfRows,
            fill: "row"
          },
          initialSlide: this.data.has('initialSlide') ? parseInt(this.data.get('initialSlide'), 0) : 0,
          spaceBetween: this.data.has('gutter') ? parseInt(this.data.get('gutter'), 0) : 0,
          centeredSlides: this.data.has('center') ? true : false,
          loop: this.data.has('loop') ? this.data.get('loop') !== 'false' : true,
          threshold: 10,
          keyboard: {
            enabled: this.data.get('arrowKeys') === true.toString(),
          },
        };

        if (this.data.has('slidesPerGroup')) {
          swiperOptions = {
            ...swiperOptions,
            slidesPerGroup: parseFloat(this.data.get('slidesPerGroup')),
          }
        }

        if (this.data.has('controls') && this.data.get('controls') == "true") {
          swiperOptions = {
            ...swiperOptions,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          }
          const nextButton = this.element.querySelector('.swiper-button-next');
          if (nextButton) {
            nextButton.addEventListener("click", function(e) {
              e.preventDefault();
              e.stopPropagation();
            });
          }
          const prevButton = this.element.querySelector('.swiper-button-prev');
          if (prevButton) {
            prevButton.addEventListener("click", function(e) {
              e.preventDefault();
              e.stopPropagation();
            });
          }
        }

        if (this.data.has('responsive')) {
          swiperOptions = {
            ...swiperOptions,
            breakpoints: {
              0: {
                slidesPerView: 1.25,
                slidesPerGroup: 1,
                spaceBetween: 0
              },
              768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: this.data.has('gutter') ? parseInt(this.data.get('gutter'), 0) : 0,
              },
              992: {
                slidesPerView: nrOfItems,
                slidesPerGroup: nrOfItems,
                spaceBetween: this.data.has('gutter') ? parseInt(this.data.get('gutter'), 0) : 0,
              },
            }
          }
        }

        if (this.data.has('showPagination')) {
          swiperOptions = {
            ...swiperOptions,
          pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
            },
          }
        }

        if (this.data.has('zoom')) {
          swiperOptions = {
            ...swiperOptions,
            zoom: {
              toggle: true,
              maxRatio: 3,
              minRatio: 1,
            },
          }
        }

        if (this.hasThumbsTarget) {
          this.thumbs = new Swiper(this.thumbsTarget, {
            modules: [Keyboard, Navigation, Pagination, Thumbs],
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesProgress: true,
            shortSwipes: false,
            threshold: 20,
            preloadImages: true,
            spaceBetween: 8,
          });
          swiperOptions.thumbs = {
            swiper: this.thumbs
          }
        }

        if (this.data.has('disableTouchMoveOnMobile') && this.data.get('disableTouchMoveOnMobile') == "true" &&
            ResponsiveHelper.isMobile) {
          swiperOptions = {
            ...swiperOptions,
            allowTouchMove: false
          }
        }

        // data-mobile-only="sm,md"
        if (this.data.has('mobile-only')) {
          const mobileOnly: string[] = this.data.get('mobile-only').split(',');
          const mobileBreakpoints: { [key:string]: number } = {};
          mobileOnly.forEach(breakpoint => {
            const [key, value] = breakpoint.split(':');
            if (!isNaN(parseFloat(value))) {
              mobileBreakpoints[key] = parseFloat(value);
            }            
          });

          swiperOptions = {
            ...swiperOptions,
            loop: false,
            breakpoints: {
              0: {
                enabled: true,
                slidesPerView: Object.keys(mobileBreakpoints).indexOf('xs') > -1 ? mobileBreakpoints['xs'] : 1.25,
                grid: {
                  rows: 1,
                  fill: "row",
                },
                ...(this.data.get('full-width-offset') === 'true' ? {
                  slidesOffsetBefore: 20,
                  slidesOffsetAfter: 20,
                }: {}),
                slidesPerGroup: 1,
              },
              576: Object.keys(mobileBreakpoints).indexOf('sm') > -1 ? {
                enabled: true,
                slidesPerView: mobileBreakpoints['sm'],
                grid: {
                  rows: 1,
                  fill: "row",
                },
                ...(this.data.get('full-width-offset') === 'true' ? {
                  slidesOffsetBefore: () => (window.innerWidth - 500) / 2 ,
                  slidesOffsetAfter: () => (window.innerWidth - 500) / 2 ,
                }: {}),
              } : {
                enabled: false,
                slidesPerView: nrOfItems,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
              768: Object.keys(mobileBreakpoints).indexOf('md') > -1 ? {
                enabled: true,
                slidesPerView: mobileBreakpoints['md'],
                grid: {
                  rows: 1,
                  fill: "row",
                },
                ...(this.data.get('full-width-offset') === 'true' ? {
                  slidesOffsetBefore: () => (window.innerWidth - (690 + 15)) / 2 ,
                  slidesOffsetAfter: () => (window.innerWidth - (690 + 15)) / 2 ,
                }: {}),
              } : {
                enabled: false,
                slidesPerView: nrOfItems,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
              992: {
                enabled: false,
                slidesPerView: nrOfItems,
                grid: {
                  rows: nrOfRows,
                  fill: "row"
                },
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
            }
          }
        }

        if (this.data.has('creative') && this.data.get('creative') == "true") {
          swiperOptions = {
            modules: [EffectCreative, Autoplay],
            loop: true,
            allowTouchMove: false,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            speed: 1000,
            effect: "creative",
            creativeEffect: {
              prev: {
                origin: "center",
                scale: 1.05,
                opacity: 0
              },
              next: {
                origin: "center",
                scale: 1.05,
                opacity: .25
              },
            }
          }
        }

        this.slider = new Swiper(this.galleryTarget, swiperOptions);

      }
    }
  }


  private fullscreenAllowed() {
    return this.data.has('fullscreenAllowed') && this.data.get('fullscreenAllowed') === 'true';
  }
}
